<template>
  <v-container fluid class="pt-0" style="min-height: 100vh" :key="user ? user.collegeTeams.length : 0">
    <v-row dense>
      <v-col cols="12" class="text-h4 text-sm-h3 text-md-h2 text-center">
        {{ team ? team.name : null }} Team Roster
      </v-col>
      <v-col cols="12">
        <v-img
          src="https://vblimages.blob.core.windows.net/images/61ad981f-2358-4538-b27f-2d0d5a5b5c2c.png"
          contain
          height="150"
        ></v-img>
      </v-col>
    </v-row>

    <v-card height="100%" flat>
      <template v-if="false">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title v-if="team">
            {{mode === 'New' ? 'New Team' : team.name}}
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <v-card-text>
        <!-- VBL -->
        <v-row dense v-if="iTeam && user.vbl">
          <v-col cols="12" sm="6">
            <v-select
              :items="teamTypes"
              v-model="iTeam.type"
              label="Team Type"
              placeholder="Select A Team Type"
              @input="loadOptions"
              :disabled="!editing"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Parent Organization"
              v-model="iTeam.owner.name"
              :items="[iTeam.owner]"
              item-text="name"
              item-value="id"
              :disabled="!editing"
              readonly
              @focus.stop="onParentClick"
            ></v-text-field>
            <v-dialog
              v-model="clubDialog"
              scrollable
              max-width="500px"
              transition="dialog-transition"
            >
              <v-card>
                <v-toolbar color="color2 color2Text--text">
                  <v-toolbar-title>
                    Parent Organization
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <organization-search
                    :selector="true"
                    :active="clubDialog"
                    @profile-selected="onNewParent"
                  ></organization-search>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <!-- VBL -->
        <v-expand-transition>
          <v-row dense v-if="iTeam && iTeam.type && iTeam.type === 'ncaa' && user.vbl">
            <v-col cols="12">
              <v-autocomplete
                :items="collegeOptions"
                v-model="college"
                attach
                label="College"
                :loading="loading.includes('colleges')"
                :search="search"
                @input="collegeSelected"
                :disabled="!editing"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition>
          <v-row dense v-if="iTeam">
            <template v-if="!view.public">
              <!-- PHOTO -->
              <v-col cols="12" sm="2">
                <div class="v-label" style="font-size: 85%;">Team Logo</div>
                  <v-img
                    :src="(unsavedImage && unsavedImage.imageURL) || iTeam.vLogoUrl"
                    max-height="50px"
                    height="50px"
                    contain
                  >
                    <v-fab-transition>
                      <v-btn
                        color="error white--text"
                        class="float-right"
                        fab x-small
                        @click.stop="unsavedImage = null"
                        v-if="unsavedImage"
                      >
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </v-fab-transition>
                    <v-expand-transition>
                        <div class="d-flex v-card--reveal color3 color3Text--text clickable" v-if="editing && view.vbl && !unsavedImage" style="height: 100%">
                          <image-input v-model="unsavedImage" ref="uploader">
                            <v-container class="fill-height">
                              <v-row dense>
                                <v-col class="text-center" cols="12" >
                                  <v-icon color="color3Text" large>fas fa-camera</v-icon>
                                  <div>{{iTeam.logoUrl ? 'Update' : 'Upload a profile picture'}}</div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </image-input>
                        </div>
                    </v-expand-transition>
                  </v-img>
              </v-col>
              <!-- Name -->
              <v-col cols="12" sm="10">
                <v-card flat outline>
                  <v-text-field
                    label="Team Name"
                    v-model="iTeam.name"
                    :disabled="!editing"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="success"
                        :disabled="!dirtyTeam"
                        @click.stop="saveTeamOnly"
                        :loading="loading.includes('save')"
                      >Update Team</v-btn>
                    </template>
                  </v-text-field>
                </v-card>
              </v-col>
            </template>
            <!-- ROSTER -->
            <v-col cols="12">
              <v-toolbar flat color="grey white--text">
                <v-toolbar-title>
                  Roster
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-select
                  :items="['Jersey', 'Name', 'TruVolley']"
                  v-model="mySort"
                  label="Sort By"
                  :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
                  solo-inverted
                  hide-details
                ></v-select>
              </v-toolbar>
            </v-col>
            <v-col cols="12" class="text-h6">
              <v-list two-line>
                <template v-for="(player, i) in displayRoster">
                  <v-expand-transition :key="`item-${i}`">
                    <v-list-item>
                      <v-list-item-avatar v-if="false">
                        <v-img :src="player.picUrl"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content v-if="editing" @click.stop="editPlayer(player, i)">
                        <v-list-item-title>{{player.jersey !== null ? `#${player.jersey}` : ''}} {{player.name}} <span class="clickable caption color3--text">Click to edit players info</span></v-list-item-title>
                        <v-list-item-subtitle class="caption" v-if="player.info">{{pipeMe([player.info.height, player.info.year, player.info.hometown])}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>{{player.jersey !== null ? `#${player.jersey}` : ''}} {{player.name}} <span v-if="user.vbl" class="ml-3 caption">Id: {{ player.playerProfileId }}</span></v-list-item-title>
                        <v-list-item-subtitle class="caption" v-if="player.info">{{pipeMe([player.info.height, player.info.year, player.info.hometown])}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="editing">
                        <v-btn icon>
                          <v-icon color="grey lighten-1" small @click.stop="deletePlayer(player, i)">fas fa-trash</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action v-else>
                        <div class="d-flex flex-row">
                          <div class="mr-2">
                            <v-list-item-title class="text-end">{{player.truVolleyScore ? fix2Me(player.truVolleyScore) : 'UR'}}</v-list-item-title>
                            <v-list-item-subtitle class="text-end">{{Math.round(player.truVolleyConfidence * 100)}}%</v-list-item-subtitle>
                          </div>
                          <v-img
                            v-if="!editing"
                            src="https://vblimages.blob.core.windows.net/images/08749c90-c742-4410-906b-5159892ecc39.png"
                            height="40"
                            width="40"
                            max-width="46px"
                            contain
                          ></v-img>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-expand-transition>
                  <v-divider :key="`divider-${i}`" v-if="i < displayRoster.length - 1"></v-divider>
                </template>
              </v-list>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-fab-transition>
                <v-btn color="color3" text @click.stop="pSearch = true" v-if="editing">
                  Add Player
                </v-btn>
              </v-fab-transition>
              <player-edit-dialog
                :open="pSearch"
                @player-selected="onPlayerSelected"
                :emitter="true"
                :allowAdd="true"
                @closed="pSearch = false"
              ></player-edit-dialog>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <!-- BACK BUTTON -->
    <v-fab-transition>
      <v-btn
        v-if="!editing"
        :key="`${editing}`"
        color="color3 color3Text--text"
        fab bottom fixed @click="() => {$router.back()}"
      >
        <v-icon>fas fa-caret-left</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- SAVE BUTTON -->
    <v-fab-transition>
      <v-btn
        v-if="editing && false"
        :key="`${dirty}`"
        color="success white--text"
        fab
        fixed
        right bottom
        :disabled="!dirty"
        class="mr-15"
        @click.stop="saveTeam"
        :loading="loading.includes('save')"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-tooltip top v-if="editing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="color3 color3Text--text"
            fab
            fixed
            right bottom
            class="mr-15"
            v-on="on"
            v-bind="attrs"
            @click.stop="pSearch = true"
            :loading="loading.includes('save')"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Add A Player</span>
      </v-tooltip>
    </v-fab-transition>
    <!-- CANCEL BUTTON -->
    <v-fab-transition>
      <v-btn
        v-if="!view.public"
        :key="fab.key"
        :color="fab.color"
        fab
        fixed
        right bottom
        @click.stop="fab.click"
        :disable="fab.disabled"
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- PLAYER EDIT -->
    <v-dialog
      v-if="!view.public"
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card v-if="selectedPlayer">
        <v-overlay color="white" absolute v-if="loading.includes('checking')">
          <v-progress-circular indeterminate color="color3"></v-progress-circular>
        </v-overlay>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{selectedPlayer.name}}</v-toolbar-title>
        </v-toolbar>
        <v-window v-model="window">
          <v-window-item :id="0">
            <v-card-text v-if="currentTeam" class="title">
              <v-alert type="warning" prominent text>
                This player is currently listed on {{ currentTeam.name }}'s roster.
              </v-alert>
              If you continue we will tranfer them from {{ currentTeam.name }}'s roster to {{ team.name }}'s roster
              <div class="text-center my-6">
                <v-btn color="success" class="mr-2" @click.stop="onContinue">continue</v-btn>
                <v-btn color="error" @click.stop="searchAgain">Search Again</v-btn>
              </div>
            </v-card-text>
          </v-window-item>
          <!-- INFO WINDOW -->
          <v-window-item :id="1">
            <v-card-text>
              <div class="text-end">
                <v-btn color="error" text x-small @click.stop="window = 2">remove player</v-btn>
              </div>
              <v-text-field
                label="Name"
                v-if="user.vbl"
                v-model.number="selectedPlayer.name"
                clearable
                autofocus
                disabled
                color="color3"
              ></v-text-field>
              <v-text-field
                label="Jersey Number"
                v-model.number="selectedPlayer.jersey"
                clearable
                autofocus
                type="number"
                color="color3"
              ></v-text-field>
              <v-select
                :items="heightOptions"
                v-model="selectedPlayer.info.height"
                label="Height"
                clearable
                color="color3"
                item-color="color3"
                menu-props="auto"
              ></v-select>
              <v-select
                :items="yearOptions"
                v-model="selectedPlayer.info.year"
                label="Year"
                clearable
                color="color3"
                item-color="color3"
                menu-props="auto"
              ></v-select>
              <v-text-field
                label="Home Town"
                v-model.number="selectedPlayer.info.hometown"
                clearable
                color="color3"
              ></v-text-field>
            </v-card-text>
          </v-window-item>
          <!-- DELETE WINDOW -->
          <v-window-item :id="2">
            <v-card-text>
              <div class="title">
                Please select the reason for removing the player
              </div>
              <v-radio-group v-model="deleteReason">
                <v-radio color="success" label="Graduated" value="Gr"></v-radio>
                <v-radio color="success" label="Retired" value="Ret"></v-radio>
                <v-radio color="success" label="Unknown" value=""></v-radio>
              </v-radio-group>
              <v-expand-transition>
                <div v-if="deleteReason !== null" class="title text-center">
                  <div>Are you sure?</div>
                  <v-btn
                    color="success"
                    small
                    @click.stop="doDelete"
                    :loading="loading.includes('save')"
                  >Yes remove this player</v-btn>
                </div>
              </v-expand-transition>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-card-actions class="justify-end">
          <v-btn color="success white--text"  fab small @click.stop="savePlayerEdit" :disabled="!dirtyInfo" :loading="loading.includes('save')">
            <v-icon>fas fa-save</v-icon>
          </v-btn>
          <v-btn color="error white--text" fab small @click.stop="cancelClick" :disabled="loading.includes('save')">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RosteredTeam from '@/classes/RosteredTeam'
import ImageInput from '@/components/Utils/ImageUploaderNew.vue'
import { mapGetters } from 'vuex'
import { slashArray, fixed2 } from '@/Filters'
import Heights from '@/classes/Heights'
import { collegeYearList } from '@/classes/ProfileHelpers'

const PlayerEditDialog = () => import('@/components/Forms/PlayerEditDialog.vue')
const OrganizationSearch = () => import('@/components/Organization/OrganizationSearch.vue')

export default {
  props: ['teamId'],
  data () {
    return {
      editing: false,
      team: null,
      iTeam: null,
      unsavedImage: null,
      colleges: [],
      clubs: [],
      loading: [],
      college: null,
      search: null,
      pSearch: false,
      selectedPlayer: null,
      selectedPlayerOG: null,
      selectedI: null,
      dialog: false,
      clubDialog: false,
      mySort: 'Jersey',
      window: 1,
      deleteReason: null,
      currentTeams: null
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView']),
    view () {
      return {
        vbl: this.user && !this.publicView && this.user.vbl,
        admin: this.user && !this.publicView && this.user.collegeTeams.length > 0 && (this.user.vbl || this.user.isTeamAdmin(this.teamId)),
        get public () {
          return !this.vbl && !this.admin
        }
      }
    },
    fab () {
      return this.editing ? {
        key: 'cancelBtn',
        color: 'error white--text',
        icon: 'fas fa-times',
        disabled: this.loading.includes('save'),
        click: this.cancelEdit
      } : {
        key: 'editBtn',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit
      }
    },
    mode () {
      return isNaN(this.teamId) ? 'New' : 'Edit'
    },
    teamTypes () {
      return [
        { text: 'Collegiate', value: 'ncaa' },
        { text: 'High School', value: 'hs' },
        { text: 'Club', value: 'club' },
        { text: 'Club Team', value: 'club-team' },
        { text: 'NA', value: 'na' }
      ]
    },
    collegeOptions () {
      return this.colleges.map(m => {
        return {
          text: m.name === m.abbreviation ? m.name : `${m.name} - ${m.abbreviation}`,
          value: m.id
        }
      })
    },
    dirty () {
      return JSON.stringify(this.team.dto) !== JSON.stringify(this.iTeam.dto) || !!this.unsavedImage
    },
    displayRoster () {
      return this.iTeam ? this.iTeam.roster.filter(f => !f.deleted) : []
    },
    heightOptions () {
      return Heights
    },
    yearOptions () {
      return collegeYearList
    },
    dirtyInfo () {
      return this.selectedPlayer && (this.selectedPlayer.id === 0 || (this.selectedPlayerOG && JSON.stringify(this.selectedPlayer) !== JSON.stringify(this.selectedPlayerOG)))
    },
    currentTeam () {
      return this.currentTeams && this.currentTeams[0]
    },
    dirtyTeam () {
      const a = this.team.dto
      a.roster = []
      const b = this.iTeam.dto
      b.roster = []
      return JSON.stringify(a) !== JSON.stringify(b) || !!this.unsavedImage
    }
  },
  methods: {
    fix2Me (x) {
      return fixed2(x)
    },
    pipeMe (arr) {
      return slashArray(arr)
    },
    loadTeam () {
      if (isNaN(this.teamId)) {
        this.team = new RosteredTeam()
        this.iTeam = new RosteredTeam()
        this.edit()
      } else {
        this.loading.push('team')
        this.$VBL.roster.team.get(this.teamId)
          .then(r => {
            if (!r.data) {
              this.$router.replace('/team/new')
            }
            this.team = new RosteredTeam(r.data)
            this.iTeam = new RosteredTeam(r.data)
            this.loadOptions(this.team.type)
          })
          .catch(e => console.log(e.response))
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'team')
          })
      }
    },
    loadOptions (selected) {
      selected === 'ncaa' && this.loadColleges()
    },
    loadColleges (force) {
      if (this.colleges.length && !force) return
      this.loading.push('colleges')
      this.$VBL.college.details.get()
        .then(r => {
          this.colleges = r.data
          if (this.iTeam.type === 'ncaa') this.college = this.iTeam.ownerId
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'colleges')
        })
    },
    onNewParent (parent) {
      this.iTeam.owner = parent
      this.iTeam.ownerId = parent.id
      this.iTeam.ownerType = 'organization'
      this.iTeam.vLogoUrl = parent.theme.logoUrl
      this.clubDialog = false
    },
    collegeSelected (id) {
      const c = this.colleges.find(f => f.id === id)
      this.iTeam.ownerType = 'college'
      this.iTeam.ownerId = id
      this.iTeam.name = c.abbreviation || c.name
      this.iTeam.logoUrl = c.logoUrl
    },
    edit () {
      this.initITeam()
      this.editing = true
    },
    onPlayerSelected (p) {
      this.pSearch = false
      var x = this.iTeam.roster.findIndex(f => f.playerProfileId === p.id)
      if (x === -1) {
        this.getCurrentTeam(p.id)
        x = this.iTeam.roster.length
        this.iTeam.roster.push({
          id: 0,
          playerProfileId: p.id,
          name: p.fullName,
          picUrl: null,
          jersey: null,
          deleted: false,
          info: {
            year: null,
            height: null,
            hometown: p.cityState,
            grad_year: null
          }
        })
      }
      this.editPlayer(this.iTeam.roster[x], x)
    },
    onSelectedConfirmed (p) {
      this.pSearch = false
      var x = this.iTeam.roster.findIndex(f => f.playerProfileId === p.id)
      if (x === -1) {
        x = this.iTeam.roster.length
        this.iTeam.roster.push({
          id: 0,
          playerProfileId: p.id,
          name: p.fullName,
          picUrl: null,
          jersey: null,
          deleted: false,
          info: {
            year: null,
            height: null,
            hometown: p.cityState,
            grad_year: null
          }
        })
      }
      this.editPlayer(this.iTeam.roster[x], x)
    },
    editPlayer (p, i) {
      this.selectedI = i
      this.selectedPlayer = JSON.parse(JSON.stringify(p))
      this.selectedPlayerOG = JSON.parse(JSON.stringify(p))
      this.dialog = true
    },
    savePlayerEdit () {
      if (this.team.id > 0) {
        var dto = {
          id: this.team.id,
          roster: [this.selectedPlayer]
        }
        if (this.currentTeams && this.currentTeams.length) {
          this.currentTeams.forEach(team => {
            team.roster.forEach(r => {
              dto.roster.push({
                id: r.id,
                deleted: true
              })
            })
          })
        }
        return this.saveTeam(dto)
      }
      this.iTeam.roster.splice(this.selectedI, 1, this.selectedPlayer)
      this.dialog = false
      this.sortThem()
    },
    deletePlayer (p, i) {
      this.editPlayer(p, i)
      this.window = 2
    },
    doDelete () {
      this.selectedPlayer.deleted = true
      if (['Gr', 'Ret'].includes(this.deleteReason)) {
        this.selectedPlayer.info.year = this.deleteReason
      }
      this.savePlayerEdit()
    },
    initITeam () {
      const dto = JSON.parse(JSON.stringify(this.team.dto))
      this.iTeam = new RosteredTeam(dto)
      if (this.team.type === 'ncaa') this.college = this.team.ownerId
      this.currentTeams = null
      this.sortThem()
    },
    cancelEdit () {
      this.college = null
      this.unsavedImage = null
      this.initITeam()
      this.dialog = false
      this.editing = false
      if (isNaN(this.teamId)) this.$router.push('/teams')
    },
    saveTeamOnly () {
      const dto = JSON.parse(JSON.stringify(this.iTeam.dto))
      dto.roster = []
      this.saveTeam(dto)
    },
    saveTeam (playerDto) {
      this.loading.push('save')
      const dto = playerDto || this.iTeam.dto
      const image = this.unsavedImage ? this.unsavedImage.formData : null
      this.$VBL.roster.team.post(dto, image)
        .then(r => {
          this.team.update(r.data)
          this.cancelEdit()
          if (this.team.id !== +this.teamId) {
            this.$router.push(`/team/${this.team.id}`)
          }
          if (playerDto) {
            this.edit()
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'save')
        })
    },
    onParentClick () {
      this.clubDialog = true
    },
    sortThem () {
      this.iTeam.sortRoster(this.mySort)
    },
    cancelClick () {
      if (this.window === 1) {
        this.dialog = false
        if (this.selectedPlayer.id === 0) {
          this.iTeam.roster.splice(this.selectedI, 1)
        }
      }
      if (this.window === 2) this.window = 1
    },
    getCurrentTeam (ppid) {
      this.loading.push('checking')
      this.$VBL.roster.team.getCurrent(ppid)
        .then(r => {
          console.log(r.data)
          this.currentTeams = r.data
          if (this.currentTeams && this.currentTeams.length) this.window = 0
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'checking')
        })
    },
    onContinue () {
      const oldInfo = this.currentTeam.roster[0].info
      if (oldInfo) {
        this.selectedPlayer.info = oldInfo
        this.selectedPlayer.info.hometown = slashArray([this.selectedPlayer.info.hometown, this.currentTeam.name])
      }
      this.window = 1
    },
    searchAgain () {
      this.dialog = false
      this.pSearch = true
      this.currentTeams = null
    }
  },
  watch: {
    mySort: 'sortThem',
    window: function (v) {
      if (v === 2) {
        this.deleteReason = null
      }
    },
    dialog: function (v) {
      if (!v) {
        this.window = 1
      }
    },
    teamId: function (n, o) {
      if (n && n !== o) {
        this.loadTeam()
      }
    }
  },
  mounted () {
    this.loadTeam()
  },
  components: {
    ImageInput,
    PlayerEditDialog,
    OrganizationSearch
  }
}
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.v-card--overlay {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
